import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AnalyticEvents } from '@app/enums';
import { AnalyticService } from '@app/services';
import { environment } from '@env/environment';

@Injectable()
export class ExternalUrlRedirectGuard {
    constructor(
        private router: Router,
        private analyticService: AnalyticService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const externalUrl = route.data['externalUrl'] ?? '';
        if (externalUrl === environment.simplyBenefitsAdminPanelUrl) {
            this.analyticService.trackEvent(AnalyticEvents.SimplyBenefitsAccessAdminPortal);
        }
        window.location.href = route.data['externalUrl'];
        return true;
    }
}

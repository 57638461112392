import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Platform } from '@app/classes';
import { FeatureFlag } from '@app/enums';
import { AuthService } from '@app/services/auth.service';
import { FeatureService } from '@app/services/feature.service';

@Injectable()
export class PermissionGuard {
    constructor(
        private auth: AuthService,
        private router: Router,
        private readonly featureService: FeatureService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean> {
        return this.featureService.has(FeatureFlag.performanceModuleSplit).then((performanceModuleSplit: boolean) => {
            let permissionsArray = Array.isArray(route.data['permission'])
                ? route.data['permission']
                : [route.data['permission']];

            if (this.PathIsSurveysModule(route) && performanceModuleSplit) {
                permissionsArray.push(...Platform.survey.permission.view);
                permissionsArray = permissionsArray.filter((x) => {
                    return x !== 'accessPerformance';
                });
            }

            const checkAllPermissions = route.data['checkAllPermissions'] ?? false;

            const canActivate = this.doesUserHavePermissions(permissionsArray, checkAllPermissions);

            const canActivateRedirectTo = route.data['canActivateRedirectTo'];

            if (!canActivate) {
                if (canActivateRedirectTo) {
                    this.router.navigate(canActivateRedirectTo);
                    return canActivate;
                }

                this.router.navigate(['/']);
            }

            return canActivate;
        });
    }

    private PathIsSurveysModule(route: ActivatedRouteSnapshot): boolean {
        const path = route.url[0]?.path;

        return path === 'surveys';
    }

    private doesUserHavePermissions(permissionsArray: string[], checkAllPermissions: boolean): boolean {
        if (checkAllPermissions) {
            return permissionsArray.every((permission) => {
                return this.auth.can(permission);
            });
        }
        return permissionsArray.some((permission) => {
            return this.auth.can(permission);
        });
    }
}

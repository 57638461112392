import { MultipleIndeedEmployerGuard } from '@app/guards/multiple-indeed-employer.guard';
import { NoIndeedEmployerGuard } from '@app/guards/no-indeed-employer.guard';
import { WithoutPermissionGuard } from '@app/guards/without-permisson-guard.service';
import { AdminGuard } from './admin.guard';
import { AuthGuard } from './auth.guard';
import { CompanyModuleGuard } from './company-module.guard';
import { EmployeeAccessGuard } from './employee-access-guard';
import { EmployeeModuleGuard } from './employee-module.guard';
import { EmployeeGuard } from './employee.guard';
import { ExternalUrlRedirectGuard } from './external-url-redirect.guard';
import { DoesntHaveFeatureRedirectGuard, FeatureGuard, HasFeatureRedirectGuard } from './feature-guards';
import { GuestGuard } from './guest.guard';
import { ModulesGuard } from './modules.guard';
import { NavigationGuard } from './navigation.guard';
import { PermissionGuard } from './permission.guard';

const Guards = [
    AdminGuard,
    AuthGuard,
    DoesntHaveFeatureRedirectGuard,
    EmployeeGuard,
    EmployeeModuleGuard,
    FeatureGuard,
    GuestGuard,
    HasFeatureRedirectGuard,
    ModulesGuard,
    NavigationGuard,
    PermissionGuard,
    WithoutPermissionGuard,
    EmployeeAccessGuard,
    NoIndeedEmployerGuard,
    MultipleIndeedEmployerGuard,
    ExternalUrlRedirectGuard,
    CompanyModuleGuard,
];

export {
    AdminGuard,
    AuthGuard,
    CompanyModuleGuard,
    DoesntHaveFeatureRedirectGuard,
    EmployeeAccessGuard,
    EmployeeGuard,
    EmployeeModuleGuard,
    ExternalUrlRedirectGuard,
    FeatureGuard,
    Guards,
    GuestGuard,
    HasFeatureRedirectGuard,
    ModulesGuard,
    MultipleIndeedEmployerGuard,
    NavigationGuard,
    NoIndeedEmployerGuard,
    PermissionGuard,
    WithoutPermissionGuard,
};

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@app/services/auth.service';

/**
 * ComapnyModuleGuard checks if the currently authenticated employee's company
 * has the required module
 */
@Injectable()
export class CompanyModuleGuard {
    constructor(
        private auth: AuthService,
        private router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
        const moduleName = route.data['companyModule'];

        const company = this.auth.company;

        if (!company) {
            this.router.navigate(['/']);
            return false;
        }

        const doesModuleExist = company.modules.some((m) => m.name === moduleName);

        if (!doesModuleExist) {
            this.router.navigate(['/']);
            return false;
        }

        return true;
    }
}

import { warnDeveloper } from '@app/functions';
import { Params, Router } from '@angular/router';

export const replaceUrlParams = (url: string[], params: { [key: string]: string }): string => {
    let urlString = url.join('/');

    for (const param in params) {
        urlString = urlString.replace(':' + param, params[param]);
    }

    return urlString;
};

export const error = (url: string): void => {
    const warningMessages = [
        'There is a feature flag guard on this route, but there is no feature flag specified',
        `The route visited was: ${url}`,
    ];
    warnDeveloper(warningMessages);

    throw new Error(`HasFeatureRedirectGuard used without proper configuration on route [${url}]`);
};

export const redirect = (router: Router, params: Params, redirectTo: string[]): void => {
    const redirectUrl = redirectTo;
    const url = replaceUrlParams(redirectUrl, params);
    router.navigate([url]);
};

import { Injectable } from '@angular/core';

import { CanDeactivateComponent } from '@app/interfaces/can-deactivate-component.interface';

@Injectable()
export class NavigationGuard {
    static warningMessage = 'Are you sure you want to leave this page? Any progress made will not be saved.';
    canDeactivate(component: CanDeactivateComponent): Promise<boolean> | boolean {
        return component.canDeactivate();
    }
}

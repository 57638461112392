import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@app/services/auth.service';

@Injectable()
export class AdminGuard {
    constructor(
        private auth: AuthService,
        private router: Router
    ) {}

    canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
        const canActivate = this.auth.isAdmin();

        if (!canActivate) {
            this.router.navigate(['/dashboard']);
        }

        return canActivate;
    }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { FeatureFlag } from '@app/enums';
import { FeatureOptions } from '@app/interfaces';
import { FeatureService } from '@app/services/feature.service';
import { error } from './helpers';

@Injectable()
/**
 * Use this to control access a route based on feature flags.
 * This guard is simple - if you have the flags, go to the route, otherwise redirect to dashboard
 */
export class FeatureGuard {
    constructor(
        private featureService: FeatureService,
        private router: Router
    ) {}

    async canActivate(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const featureOptions = (routeSnapshot.data as FeatureOptions).feature;
        const { flags } = featureOptions;

        if (!flags) {
            error(state.url);
        }

        const canAccessFeature = (
            await Promise.all(flags.map((flag: FeatureFlag) => this.featureService.has(flag)))
        ).every((hasFlag: boolean) => hasFlag);

        if (!canAccessFeature) {
            this.router.navigate(['/']);
        }

        return Promise.resolve(canAccessFeature);
    }
}

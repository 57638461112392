import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { FeatureFlag } from '@app/enums';
import { FeatureRedirectOptions } from '@app/interfaces';
import { FeatureService } from '@app/services/feature.service';
import { error, redirect } from './helpers';

@Injectable()
/**
 * Use this to redirect a user if they have a set of feature flags.
 * Otherwise, they will still continue to the original route.
 */
export class HasFeatureRedirectGuard {
    constructor(
        private featureService: FeatureService,
        private router: Router
    ) {}

    async canActivate(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const featureOptions = (routeSnapshot.data as FeatureRedirectOptions).feature;
        const { flags, redirectTo, redirectToReplace, withoutFeatureFlagsRedirectTo } = featureOptions || {};

        if (!flags || (!redirectTo && !redirectToReplace)) {
            error(state.url);
        }

        const hasFeatureFlags = (
            await Promise.all((flags ?? []).map((flag: FeatureFlag) => this.featureService.has(flag)))
        ).every((hasFlag: boolean) => hasFlag);

        if (hasFeatureFlags) {
            if (redirectToReplace) {
                const replaced = state.url.replace(redirectToReplace[0], redirectToReplace[1]);
                redirect(this.router, routeSnapshot.params, [replaced]);
            } else {
                redirect(this.router, routeSnapshot.params, redirectTo ?? []);
            }
        }

        if (!hasFeatureFlags && withoutFeatureFlagsRedirectTo) {
            redirect(this.router, routeSnapshot.params, withoutFeatureFlagsRedirectTo);
        }

        // Always want to pass through to original route if no redirect occurs.
        return Promise.resolve(true);
    }
}

import { Injectable } from '@angular/core';
import { Route, Router } from '@angular/router';
import { AuthService } from '@app/services/auth.service';

@Injectable()
export class ModulesGuard {
    constructor(
        private auth: AuthService,
        private router: Router
    ) {}

    canLoad(route: Route): boolean {
        const baseUrl: string = route.path;

        switch (baseUrl) {
            // Everyone has access to company and employees

            // Payroll
            case 'payroll':
                return this.checkPermission('accessPayroll');

            // Time Off
            case 'time-off':
                return this.checkPermission('accessTimeOff');

            // Performance
            case 'Performance':
                return this.checkPermission('accessPerformance');

            // Survey
            case 'Survey':
                return this.checkPermission('accessSurvey');

            // Recruiting
            case 'recruiting':
                return this.checkPermission('accessRecruiting');

            // Benefits
            case 'benefits':
                return this.checkPermission('accessbenefits');

            // Reporting
            case 'reporting':
                return this.checkPermission('accessReporting');

            // Settings
            case 'settings':
                return this.checkPermission('accessSettings');

            default:
                return true;
        }
    }

    private checkPermission(permission: string): boolean {
        if (!this.auth.can(permission)) {
            this.router.navigate(['/dashboard']);
            return false;
        }

        return true;
    }
}

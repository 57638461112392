import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot } from '@angular/router';
import { AUTH_ERROR } from '@app/components/auth0/auth0.view';
import { TokenService } from '@app/services';
import { AuthService } from '@app/services/auth.service';
import { Auth0OptionalService } from '@app/services/auth0optional.service';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { environment } from '@env/environment';
import { Observable, from, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

@Injectable()
export class AuthGuard {
    constructor(
        private router: Router,
        private auth: AuthService,
        private auth0: Auth0Service,
        private auth0Optional: Auth0OptionalService,
        private tokenService: TokenService
    ) {}

    canLoad(route: Route): Promise<boolean> | Observable<boolean> {
        const url: string = '/' + route.path;
        return this.checkLogin(url);
    }

    canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | Observable<boolean> {
        const url: string = '/' + state.url;
        return this.checkLogin(url)
            .toPromise()
            .then((isLoggedIn) => {
                if (isLoggedIn && this.auth.redirectUrl) {
                    this.router.navigateByUrl(this.auth.redirectUrl);
                    this.auth.redirectUrl = null;
                    return false;
                }
                return isLoggedIn;
            });
    }

    /**
     * Check with the auth service if the user is authenticated
     * if not redirect to login page
     * @param  {string}  url The URL attempting to be accessed
     * @return {boolean}     If the user canLoad
     */
    private checkLogin(url: string): Observable<boolean> {
        if (!this.auth0Optional.auth0Optional) {
            return this.auth0.isAuthenticated$.pipe(
                take(1),
                switchMap((isAuthenticated) => {
                    if (isAuthenticated) {
                        return this.checkHumiAuth(url);
                    } else {
                        this.router.navigate(['/login']);
                        return of(false);
                    }
                })
            );
        } else {
            return this.checkHumiAuth(url);
        }
    }

    /**
     * Check the login state with the Humi auth service
     * @param  {string}  url The URL attempting to be accessed
     * @return {boolean}     If the user canLoad
     */
    private checkHumiAuth(url: string): Observable<boolean> {
        if (this.auth.isAuthenticated && this.auth.hydrated) {
            if (this.checkFlags(url)) {
                return of(true);
            }
        }

        return from(this.auth.checkPersistentTokens()).pipe(
            switchMap(() => {
                return from(this.auth.hydrate()).pipe(
                    map(() => {
                        // Model is now hydrated, continue
                        return this.checkFlags(url);
                    })
                );
            }),
            catchError(() => {
                this.auth.redirectUrl = url;
                // There's been an error with authentication that we want to clear the user's auth0 state and redirect them to an error page
                if (this.auth.authenticationError) {
                    localStorage.setItem(AUTH_ERROR, this.auth.authenticationError);
                    this.auth.authenticationError = undefined;
                    this.tokenService.removeAuth0Token();
                    this.auth0.logout({
                        logoutParams: {
                            returnTo: environment.url + '/client-error',
                        },
                    });
                    return of(false);
                }
                // Retrying authentication after an error can sometimes compensate for an intermittent error in auth
                this.auth.retryAuth0LoginAfterFailure();
                return of(false);
            })
        );
    }

    /**
     * Catch users pressing back button, or navigating away from registration page
     * @param  {string}  url The URL attempting to be accessed
     * @return {boolean}     If the user canLoad
     */
    private checkFlags(url: string): boolean {
        // Always allow the user to logout
        if (url.includes('logout')) {
            return true;
        }

        // Route flags
        const isEmployeeSetupRoute = url.includes('setupEmployee');
        const isCompanySetupRoute = !isEmployeeSetupRoute && url.includes('setup');

        // If the user is authenticated and not on the company setup route
        // check the company setup flags
        if (this.auth.isAuthenticated && !isCompanySetupRoute) {
            // If no company setup, perform setup
            if (!this.checkFlagsForCompany()) {
                return false;
            }
        }
        // If the user is authenticated and not on the company or employee setup page
        // check the employee setup flags
        if (this.auth.isAuthenticated && !isEmployeeSetupRoute && !isCompanySetupRoute) {
            // Redirect based on flag status
            return this.checkFlagsForEmployee();
        }
        return true;
    }

    /**
     * Check if employee needs to setup account
     */
    private checkFlagsForEmployee(): boolean {
        // User has flags when they use the login dialog
        if (this.auth.flags && Object.keys(this.auth.flags).length) {
            // Check if the employee has not completed onboarding
            if (!this.auth.flags.hasCompletedOnboarding) {
                this.router.navigate(['/setupEmployee']);
                return false;
            }
        }
        // user doesn't have flags on navigation/back/direct
        if (this.auth.account && this.auth.user) {
            // check if user has completed onboarding
            if (this.auth.employee.status === 'onboarding') {
                this.router.navigate(['/setupEmployee']);
                return false;
            }
            // check if employee status is onboarding
            if (this.auth.user.status === 'onboarding') {
                this.router.navigate(['/setupEmployee']);
                return false;
            }
        }
        return true;
    }

    /**
     * Check if company needs to setup account
     */
    private checkFlagsForCompany(): boolean {
        // User has flags when they use the login dialog
        if (this.auth.flags && Object.keys(this.auth.flags).length) {
            // Check if company lacks subscription
            const needsSetup = !this.auth.flags.companySubscriptionCreated;
            if (this.auth.isAdmin() && needsSetup) {
                this.router.navigate(['/setup']);
                return false;
            }
        }
        // user doesnt have flags on navigation/back/direct
        if (this.auth.account) {
            // Check if hydrated company lacks subscription
            if (this.auth.isAdmin() && !this.auth.company.cardLastFour) {
                this.router.navigate(['/setup']);
                return false;
            }
        }
        return true;
    }
}

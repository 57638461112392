import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Employer } from '@models/recruiting/indeed/employer.model';

@Injectable()
export class NoIndeedEmployerGuard {
    constructor(private router: Router) {}

    async canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean> {
        const [employers] = await Employer.all();

        if (employers.length > 0) {
            this.router.navigate(['/settings', 'integrations-v2', 'employers', 'select']);
        }

        return true;
    }
}

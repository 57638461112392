import { Injectable } from '@angular/core';
import { FeatureFlag } from '@app/enums';
import { FeatureService } from './feature.service';

@Injectable({
    providedIn: 'root',
})
export class Auth0OptionalService {
    private _isAuth0Optional = true;

    constructor(private featureService: FeatureService) {}

    get auth0Optional(): boolean {
        return this._isAuth0Optional;
    }

    async setAuth0Optional(): Promise<void> {
        this._isAuth0Optional = await this.featureService.has(FeatureFlag.auth0Optional);
    }
}

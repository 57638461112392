import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@app/services/auth.service';

@Injectable()
export class WithoutPermissionGuard {
    constructor(
        private auth: AuthService,
        private router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
        const permissionsArray = Array.isArray(route.data['cannot']) ? route.data['cannot'] : [route.data['cannot']];

        const canActivate = !permissionsArray.some((permission) => {
            return this.auth.can(permission);
        });

        const withoutPermissionRedirectTo = route.data['withoutPermissionRedirectTo'];

        if (!canActivate) {
            if (withoutPermissionRedirectTo) {
                this.router.navigate(withoutPermissionRedirectTo);
                return canActivate;
            }

            this.router.navigate(['/']);
        }

        return canActivate;
    }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@app/services/auth.service';

/**
 * GuestGuard only allows access if the user is NOT logged in.
 */
@Injectable()
export class GuestGuard {
    constructor(
        private auth: AuthService,
        private router: Router
    ) {}

    canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
        return this.checkLogin();
    }

    /**
     * Check if the user is logged in, if so redirect
     * this is used to protect guest routes (/login)
     * from authenticated users
     */
    private checkLogin(): boolean {
        if (!this.auth.isAuthenticated) {
            return true;
        }

        this.router.navigate(['/dashboard']);

        return false;
    }
}

import { ModelMixin } from '@app/models/core/base-generic.model';
import { IndeedBillingState } from '@applicant-tracker/enums/indeed-billing-state.enum';

export class Employer extends ModelMixin<Employer>() {
    protected static _resource = 'applicantTracker/indeed/employers';
    protected static _version = 'v2';
    protected static _type = 'indeedEmployers';
    protected static _serializeAttributes = [
        'address1',
        'address2',
        'city',
        'province',
        'postalCode',
        'companyName',
        'contactName',
        'contactPhone',
        'numberOfEmployees',
        'website',
        'billingEmail',
        'industry',
    ];

    get company(): string {
        return this._attributes['company'];
    }

    get address1(): string {
        return this._attributes['address1'];
    }

    get address2(): string {
        return this._attributes['address2'];
    }

    get city(): string {
        return this._attributes['city'];
    }

    get province(): string {
        return this._attributes['province'];
    }

    get postalCode(): string {
        return this._attributes['postalCode'];
    }

    get companyName(): string {
        return this._attributes['companyName'];
    }

    get contactName(): string {
        return this._attributes['contactName'];
    }

    get contactPhone(): string {
        return this._attributes['contactPhone'];
    }

    get numberOfEmployees(): string {
        return this._attributes['numberOfEmployees'];
    }

    get website(): string {
        return this._attributes['website'];
    }

    get billingEmail(): string {
        return this._attributes['billingEmail'];
    }

    get billingState(): IndeedBillingState {
        return this._attributes['billingState'];
    }

    get industry(): string {
        return this._attributes['industry'];
    }

    get employerNumber(): string {
        return this._attributes['id'];
    }

    set address1(val: string) {
        this._attributes['address1'] = val;
    }

    set address2(val: string) {
        this._attributes['address2'] = val;
    }

    set city(val: string) {
        this._attributes['city'] = val;
    }

    set province(val: string) {
        this._attributes['province'] = val;
    }

    set postalCode(val: string) {
        this._attributes['postalCode'] = val;
    }

    set companyName(val: string) {
        this._attributes['companyName'] = val;
    }

    set contactName(val: string) {
        this._attributes['contactName'] = val;
    }

    set contactPhone(val: string) {
        this._attributes['contactPhone'] = val;
    }

    set numberOfEmployees(val: string) {
        this._attributes['numberOfEmployees'] = val;
    }

    set website(val: string) {
        this._attributes['website'] = val;
    }

    set billingEmail(val: string) {
        this._attributes['billingEmail'] = val;
    }

    set industry(val: string) {
        this._attributes['industry'] = val;
    }
}

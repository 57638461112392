import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@app/services/auth.service';

/**
 * EmployeeModuleGuard checks if the currently authenticated employee
 * has the required module
 */
@Injectable()
export class EmployeeModuleGuard {
    constructor(
        private auth: AuthService,
        private router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
        const moduleName = route.data['employeeModule'];

        const employee = this.auth.employee;
        if (!employee) {
            this.router.navigate(['/']);
            return false;
        }

        if (!employee.hasModule(moduleName)) {
            this.router.navigate(['/']);
            return false;
        }

        return true;
    }
}

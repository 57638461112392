import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@app/services/auth.service';

@Injectable()
export class EmployeeAccessGuard {
    constructor(private auth: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot, _: RouterStateSnapshot): boolean {
        const employeeId = parseInt(route.params['employee']);
        return this.auth.canAccessEmployee(employeeId);
    }
}
